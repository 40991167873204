import React, { useState, useEffect } from 'react'
import style from './ViolationsSuspensions.module.scss'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Sidebar from 'react-sidebar'
import axios from 'axios'
import ViolationsGrid from './ViolationsGrid'
import SuspensionsGrid from './SuspensionsGrid'

const ViolationsSuspensions = () => {

    const { t, i18n } = useTranslation('common')
    const { user, clientProfiles, isAuthenticated } = useSelector((state) => state.user)
    const [show, setShow] = useState(false)
    const [suspSidebarState, setSuspSidebarState] = useState(false)
    const [vioSidebarState, setVioSidebarState] = useState(false)
    const [violations, setViolations] = useState(null)
    const [suspensions, setSuspensions] = useState(null)

    useEffect(() => {
        clientProfiles?.Dealing_Type_Id == 2 || clientProfiles?.Dealing_Type_Id == 3
            ? setShow(true)
            : setShow(false)
        handleProfileViolationsBans()
    }, [])

    const handleProfileViolationsBans = async () => {
        const res = await axios.post(
            `${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/PROFILES/GET_CLIENT_PROFILE_VIOLATIONS`,
            {
                'Client_Profile_Id': clientProfiles?.Client_Profile_Id
            },
            {
                headers: {
                    Authorization: `Bearer ${user?.access_token}`,
                },
            }
        )
        setViolations(res?.data?.Data?.Client_Profile_Violations?.Violations_List)
        setSuspensions(res?.data?.Data?.Client_Profile_Blocking)
    }

    const handleViolations = open => setVioSidebarState(open)
    const handleSuspensions = open => setSuspSidebarState(open)

    return (
        <>
            <Sidebar
                sidebar={<ViolationsGrid violations={violations} toggleSidebar={handleViolations} />}
                open={vioSidebarState}
                onSetOpen={handleViolations}
                shadow={true}
                transitions={true}
                overlayClassName='violOverlayStyle'
                // sidebarClassName='vioSidebarStyle'
                sidebarClassName={[
                    'vioSidebarStyle',
                    i18n.language == 'en' ? 'vioSideBarEnStyle' : 'vioSideBarArStyle',
                    i18n.language == 'ar' && !vioSidebarState && 'vioSideBarArClose'
                ].join(' ')}
            >
                {show && violations?.length > 0 ?
                    <div
                        className={style.wrapper}
                    >
                        <div
                            className={[
                                style.violations,
                                i18n.language == 'en' ? style.dirEN : style.dirAR
                            ].join(' ')}
                            onClick={() => {
                                handleSuspensions(false)
                                handleViolations(!vioSidebarState)
                            }}
                        >
                            <span className={style.violationsIcon}>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 512 512" xmlSpace="preserve">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                        <path d="M2290.6,5103c-688.3-83.9-1256.8-441.6-1605.1-1009c-89.5-144.6-181.4-389.7-222.6-585.1c-18.1-89.4-21.8-195.4-27.8-724.2
                                            l-7.3-619.3l-183.9-252.8C143.5,1773.4,49.2,1632.1,34.7,1599C2,1523.9-10.1,1382.6,9.3,1300.9c41.1-176.6,175.4-313.5,379.8-389.7
                                            c59.3-23.2,87.1-24.3,545.5-29.8l481.4-5.5l21.8-78.4c87.1-312.4,343.5-587.3,666.5-712.1C2451.5-49.2,2848.2-23.8,3168.7,155
                                            c123.4,69.5,287.9,215.3,366.5,325.7c67.7,96,122.2,209.8,152.4,316.8l21.8,78.4l481.4,5.5c431.8,5.5,488.7,8.8,543.1,26.5
                                            c168.1,57.4,300,176.6,360.5,324.6c21.8,50.8,26.6,86.1,25.4,184.4c0,160.1-10.9,182.2-238.3,495.7l-183.9,252.8l-7.3,619.3
                                            c-7.3,646.9-8.5,663.5-66.5,875.4c-66.5,242.9-217.7,523.3-389.5,720.9c-78.6,89.4-260.1,255-359.3,325.7
                                            C3428.8,5026.8,2847,5172.5,2290.6,5103z M1961.6,3342.2c14.5-6.6,156-129.2,313.3-272.7l287.9-261.6l292.7,267.2
                                            c192.3,174.4,306,269.4,327.8,274.9c53.2,11,135.5-1.1,175.4-26.5c65.3-43.1,96.8-152.3,61.7-215.3
                                            c-7.3-13.2-141.5-142.4-298.8-285.9l-286.7-262.7l292.7-267.2c244.3-224.1,293.9-276,307.2-314.6c39.9-127-95.6-250.6-233.5-214.2
                                            c-43.5,12.1-100.4,57.4-345.9,280.4l-292.7,267.2l-287.9-261.6c-157.2-143.5-298.8-266.1-313.3-272.7
                                            c-68.9-32-188.7-3.3-235.9,56.3c-27.8,36.4-41.1,111.5-29,160.1c6,19.9,110.1,123.6,301.2,299.2l291.5,267.2l-291.5,268.3
                                            c-191.1,174.4-295.1,278.2-301.2,298.1c-21.8,83.9,19.4,176.6,94.3,209.8C1837,3356.5,1924.1,3359.8,1961.6,3342.2z"/>
                                    </g>
                                </svg>
                            </span>
                            <span>{t('violations')}</span>
                        </div>
                    </div>
                    : ''}
            </Sidebar>

            <Sidebar
                sidebar={<SuspensionsGrid suspensions={suspensions} toggleSidebar={handleSuspensions} />}
                open={suspSidebarState}
                onSetOpen={handleSuspensions}
                shadow={true}
                transitions={true}
                overlayClassName='violOverlayStyle'
                // sidebarClassName='suspSidebarStyle'
                sidebarClassName={[
                    'suspSidebarStyle',
                    i18n.language == 'en' ? 'suspSideBarEnStyle' : 'suspSideBarArStyle',
                    i18n.language == 'ar' && !suspSidebarState && 'suspSideBarArClose'
                ].join(' ')}
            >
                {show && suspensions?.length > 0 ?
                    <div
                        className={style.wrapper}
                    >
                        <div
                            className={[
                                style.suspensions,
                                i18n.language == 'en' ? style.dirEN : style.dirAR
                            ].join(' ')}
                            onClick={() => {
                                handleViolations(false)
                                handleSuspensions(!suspSidebarState)
                            }}
                        >
                            <span className={style.suspensionsIcon}>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                    viewBox="0 0 512 512" xmlSpace="preserve">
                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)">
                                        <path d="M2290.6,5103c-725.8-89.4-1337.8-495.7-1653.5-1097.3c-65.3-125.9-136.7-325.7-169.3-476.9
                                            c-24.2-115.9-26.6-166.7-32.7-744.1l-7.3-619.3l-183.9-252.8C143.5,1773.4,49.2,1632.1,34.7,1599C2,1523.9-10.1,1382.6,9.3,1300.9
                                            c41.1-176.6,175.4-313.5,379.8-389.7c59.3-23.2,87.1-24.3,545.5-29.8l481.4-5.5l21.8-78.4c87.1-312.4,343.5-587.3,666.5-712.1
                                            C2451.5-49.2,2848.2-23.8,3168.7,155c123.4,69.5,287.9,215.3,366.5,325.7c67.7,96,122.2,209.8,152.4,316.8l21.8,78.4l481.4,5.5
                                            c431.8,5.5,488.7,8.8,543.1,26.5c168.1,57.4,300,176.6,360.5,324.6c21.8,50.8,26.6,86.1,25.4,184.4c0,160.1-10.9,182.2-238.3,495.7
                                            l-183.9,252.8l-7.3,619.3c-7.3,646.9-8.5,663.5-66.5,875.4c-66.5,242.9-217.7,523.3-389.5,720.9c-78.6,89.4-260.1,255-359.3,325.7
                                            C3428.8,5026.8,2847,5172.5,2290.6,5103z M2792.6,3770.5c121-30.9,203.2-62.9,293.9-118.1c516.5-306.9,592.7-962.7,159.7-1361.2
                                            c-429.4-397.4-1157.6-327.9-1493.9,143.5c-107.7,150.1-151.2,285.9-151.2,478s43.5,327.9,150,476.9
                                            c143.9,200.9,367.7,340,630.2,390.8C2478.1,3799.2,2698.2,3793.7,2792.6,3770.5z"/>
                                        <path d="M2421.2,3426.1c-269.7-66.2-457.2-301.4-435.5-546.5c6-75.1,39.9-187.7,54.4-187.7c15.7,0,769.3,694.4,762,702.1
                                            c-4.8,3.3-46,16.6-90.7,27.6C2616,3445.9,2509.5,3447,2421.2,3426.1z"/>
                                        <path d="M2695.8,2785.8c-209.3-191-377.4-348.9-375-352.2c16.9-13.2,135.5-42,199.6-47.5c341.1-28.7,650.8,253.9,619.3,565.2
                                            c-7.3,67.3-42.3,182.2-55.6,182.2C3080.4,3133.5,2905.1,2976.8,2695.8,2785.8z"/>
                                    </g>
                                </svg>
                            </span>
                            <span>{t('suspensions')}</span>
                        </div>
                    </div>
                    : ''}
            </Sidebar>
        </>
    )

}

export default ViolationsSuspensions



