import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "components/UIComponents/Header/Header";
import Footer from "components/UIComponents/Footer/Footer";
import style from "./Dashboard.module.scss";
import { Container, Row, Col } from "react-bootstrap";
import Drafts from "./Drafts/Drafts";
import NewRequests from "./NewRequests/NewRequests";
import DuePayments from "./DuePayments/DuePayments";
import ReturnRequests from "./ReturnRequests/ReturnRequests";
import UnderProcessRequests from "./UnderProcessRequests/UnderProcessRequests";
import CompletedRequests from "./CompletedRequests/CompletedRequests";
import RejectedRequests from "./RejectedRequests/RejectedRequests";
import Payments from "./Payments/Payments";
import ServicesSupport from "./ServicesSupport/ServicesSupport";
import Suggestions from "./Suggestions/Suggestions";
import InspectionDates from "./InspectionDates/InspectionDates";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import ScrollButton from "../../UIComponents/ScrollToTopButton";
import LoadingOverlay from "components/UIComponents/LoadingOverlay";
import ViolationsSuspensions from "./../../UIComponents/ViolationsSuspensions/ViolationsSuspensions";
import { messageAdded } from "features/service/errorMessageSlice";
import { post } from "../../../api/base.js";

const Dashboard = () => {
	const { t } = useTranslation("common");
	const [data, setData] = useState();
	const [activeGrid, setActiveGrid] = useState();
	const { user, clientProfiles } = useSelector((state) => state.user);
	const [isReady, setIsReady] = useState(false)
	const dispatch = useDispatch();

	useEffect(() => {
		const getDashboardStatistics = async () => {
			const res = await post(
				`${process.env.REACT_APP_API_URL}/PORTAL/CLIENTS/DASHBOARD/GET_CLIENT_DASHBOARD_STATISTICS`,
				{
					Client_Profile_Id: clientProfiles?.Client_Profile_Id,
				},
				{
					headers: {
						Authorization: `Bearer ${user?.access_token}`,
						"Content-Type": "application/json",
					},
				}
			);
			setData(res.Data[0]);
		};
		getDashboardStatistics();
		const interval = setInterval(getDashboardStatistics, 120000);

		return () => {
			clearInterval(interval);
		};
	}, []);

	useEffect(() => {
		if (!isReady && activeGrid) {
			window.scrollTo({
				top: document.querySelector(".gridCon").offsetTop - 160,
				left: 0,
				behavior: "smooth",
			});
		}
	}, [isReady])

	useEffect(() => {
		if (user && user?.Client_Profiles[0]?.Profile_Active_Status_Id !== 1) {
			dispatch(
				messageAdded({
					errorEnglish: `Account Status: ${user?.Client_Profiles[0]?.Profile_Active_Status_Name_EN}, Some services will not be available untill the account is activated`,
					errorArabic: `حالة الحساب: ${user?.Client_Profiles[0]?.Profile_Active_Status_Name_AR}, بعض الخدمات غير متاحة حتى يتم تعديل حالة الحساب الى فعال`,
					warning: true,
					errorColor: "warning",
				})
			);
			document.getElementById('theme').style.marginTop = '73px';
			document.getElementById('header').style.top = '73px';
		}
	}, []);

	const list = [
		{
			title: t("drafts"),
			count: `${data && data.Drafts_Requests_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 278.9 340.6">
						<style>{".prefix__st1{fill:#a4c284}"}</style>
						<path
							d="M22.8 340.6c-3.5 0-8.5-.8-12.9-4.4-6.6-5.3-9.8-14.9-9.4-28.4V34.6C0 31.8-1.6 19.5 5.9 10.2 11.4 3.4 20.1 0 31.9 0h130.9c1.3 0 2.5.5 3.3 1.4l111.5 116.2c1.3 1.3 1.6 3.3.9 5-.7 1.7-2.4 2.8-4.2 2.8h-75.4c-2.1.2-16 .9-25.4-7-5.1-4.3-7.9-10.2-8.3-17.6v-87l-4.4-4.6H31.7c-8.9 0-15.2 2.3-18.8 6.8-5.5 6.8-3.5 17.1-3.4 17.2.1.3.1.7.1 1v273.7c-.3 10.4 1.8 17.6 5.9 21 4.2 3.4 9.4 2.2 9.6 2.2.3-.1.7-.1 1.1-.1h166.9v9.2H26.7c-.8.2-2.2.4-3.9.4zM174.3 23.4v77.1c.3 4.6 2 8.2 5.1 10.9 6.9 5.9 18.7 4.9 18.8 4.8h65.2l-89.1-92.8z"
							fill="#b4975a"
						/>
						<path
							className="prefix__st1"
							d="M30.9 289.4h125.5v9.2H30.9zM32.1 250.6h70.2v9.2H32.1zM99.3 222.6c-.2 0-.5 0-.7-.1-2.5-.4-4.2-2.7-3.8-5.3l14.8-95.4c.4-2.5 2.7-4.2 5.3-3.8 2.5.4 4.2 2.7 3.8 5.3l-14.8 95.4c-.4 2.3-2.4 3.9-4.6 3.9z"
						/>
						<path
							className="prefix__st1"
							d="M101.2 259.8c-1.4 0-2.9-.7-3.8-1.9L88.2 245c-1.5-2.1-1-4.9 1.1-6.4s4.9-1 6.4 1.1l6.8 9.6 9.7-5.3 26.3-154c.4-2.5 2.8-4.2 5.3-3.8 2.5.4 4.2 2.8 3.8 5.3L121 247.7c-.2 1.4-1.1 2.6-2.3 3.3l-15.2 8.4c-.8.2-1.6.4-2.3.4zM129 90.1c-.3 0-.6 0-.9-.1-2.5-.5-4.1-2.9-3.7-5.4l3.3-17.6c.5-2.5 2.9-4.1 5.4-3.7 2.5.5 4.1 2.9 3.7 5.4l-3.3 17.6c-.3 2.3-2.3 3.8-4.5 3.8z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("new_requests"),
			count: `${data && data.New_Requests_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 403.2 329.9">
						<style>{".prefix__st1{fill:#a4c284}"}</style>
						<path
							d="M156.9 329.9c-3.4 0-8.2-.8-12.5-4.2-6.4-5.2-9.5-14.4-9.2-27.5V33.5c-.5-2.7-2.1-14.6 5.2-23.6 5.3-6.6 13.8-9.9 25.2-9.9h126.8c1.2 0 2.4.5 3.2 1.4l108.1 112.5c1.2 1.3 1.6 3.2.9 4.8-.7 1.6-2.3 2.7-4.1 2.7h-73c-2.1.2-15.5.9-24.6-6.8-4.9-4.2-7.6-9.9-8.1-17.1V13.2l-4.2-4.4h-125c-8.6 0-14.7 2.2-18.2 6.6-5.3 6.6-3.3 16.6-3.3 16.7.1.3.1.6.1 1v265.2c-.3 10 1.7 17 5.7 20.3s9.1 2.2 9.3 2.1c.3-.1.7-.1 1-.1h161.7v8.9H160.7c-.8.2-2.1.4-3.8.4zM303.7 22.7v74.7c.3 4.4 1.9 8 4.9 10.5 6.7 5.7 18.1 4.7 18.2 4.7H390l-86.3-89.9z"
							fill="#b4975a"
						/>
						<path
							className="prefix__st1"
							d="M171.6 280.3h121.6v8.9H171.6zM172.7 242.8h68v8.9h-68z"
						/>
						<path fill="none" d="M400.5 117v208.5" />
						<path
							className="prefix__st1"
							d="M288.1 265.8L262.8 230c-.4-.6-.6-1.3-.6-2 .2-8 2.5-13.5 6.9-16.6 6.1-4.2 13.5-1.6 13.8-1.5.6.2 1.1.5 1.4 1l11.5 14.1v-45.1c1.3-15.9 16.4-19.8 17.1-20 6.9-2.4 13.7-.7 19 4.9 3.7 4 5.8 9.4 5.8 15.4v17.2c4.3-1.3 8.3-1.2 12 .2 4.5 1.7 7.5 5.1 9.4 7.9 2.3-1.9 4.6-2.5 6.3-2.6 6-.2 10.7 4.8 11.7 6 14.3 14 2.1 51.7.7 55.9l-6.2-2.1c3.5-10.5 10.6-39.9.8-49.2l-.3-.3c-.8-1-3.9-3.8-6.4-3.7-1.5.1-3 1.3-4.5 3.5-.7 1-1.9 1.6-3.1 1.5-1.2-.1-2.3-1-2.7-2.1 0-.1-2.6-6.6-8.1-8.7-2.8-1.1-6-.9-9.6.6v10.2c0 1.8-1.5 3.3-3.3 3.3s-3.3-1.5-3.3-3.3v-34.2c0-4.3-1.4-8.2-4-10.9-3.5-3.8-7.6-4.8-12.3-3.1-.7.2-11.5 3.1-12.4 14v54.1c0 1.4-.9 2.6-2.2 3.1s-2.8.1-3.7-1l-16.7-20.4c-1.5-.3-4.7-.8-7.1.9-2.9 2-3.8 6.5-4 10.2l24.7 34.9-5.3 3.7zM104.7 315.7H4.5c-2.5 0-4.5-2-4.5-4.5v-246c0-2.5 2-4.5 4.5-4.5h100.2v8.9H8.9v237.1h95.8v9z"
						/>
						<path
							className="prefix__st1"
							d="M95.1 148.7H54.6v-8.9h36V92.9H27.9V84h67.2c2.5 0 4.5 2 4.5 4.5v55.8c-.1 2.4-2.1 4.4-4.5 4.4zM75.7 177.5v20.2h19.4v8.7H75.7v20.4h-9.1v-20.4H47.2v-8.7h19.4v-20.2h9.1z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("due_payment_requests"),
			count: `${data && data.Due_Payments_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 275.2 336.5">
						<style>
							{
								".prefix__st0{fill:#b4975a}.prefix__st2{fill:#a4c284}"
							}
						</style>
						<path
							className="prefix__st0"
							d="M22.6 336.5c-3.5 0-8.4-.8-12.8-4.3-6.6-5.3-9.7-14.7-9.3-28V34C0 31.3-1.6 19.1 5.8 9.9 11.2 3.2 19.8-.2 31.5-.2H161c1.2 0 2.4.5 3.3 1.4l110.3 114.9c1.3 1.3 1.6 3.3.9 4.9-.7 1.7-2.4 2.8-4.2 2.8h-74.6c-2.1.2-15.8.9-25.1-6.9-5-4.2-7.8-10.1-8.3-17.4V13.4L159 8.9H31.4c-8.8 0-15 2.3-18.6 6.7-5.4 6.8-3.4 16.9-3.4 17 .1.3.1.6.1 1v270.7c-.3 10.2 1.7 17.4 5.9 20.8 4.1 3.4 9.3 2.2 9.5 2.2.3-.1.7-.1 1.1-.1h165v9.1H26.4c-.8.1-2.1.2-3.8.2zM172.4 22.9v76.3c.3 4.5 1.9 8.1 5 10.7 6.8 5.8 18.5 4.8 18.6 4.8h64.5l-88.1-91.8z"
						/>
						<path
							className="prefix__st0"
							d="M19.3 285.9h124.2v9.1H19.3zM19.3 202.8h124.2v9.1H19.3zM20.5 247.6H90v9.1H20.5z"
						/>
						<path fill="none" d="M253 119.2V332" />
						<path
							className="prefix__st2"
							d="M173.3 187.7c-7.1 0-13.8 1.3-20.1 3.6v8.2c6.2-2.7 13-4.2 20.1-4.2 27.8 0 50.4 22.6 50.4 50.4 0 27.8-22.6 50.4-50.4 50.4-7.2 0-13.9-1.5-20.1-4.2v8.1c6.3 2.3 13 3.7 20.1 3.7 32 0 58-26 58-58s-26-58-58-58zM261.5 185.2c-16 0-28.5-4.2-37.4-12.6-13.4-12.5-13.2-29.7-13.2-30.4l9.1.1c0 .1 0 14 10.4 23.7 8.5 7.9 21.6 11.1 38.9 9.7l.8 9.1c-3 .2-5.9.4-8.6.4zM227.1 332.4c-1.7-20 2.4-35.5 12.2-46 12.5-13.4 29.7-13.2 30.4-13.2l-.1 9.1c-.2 0-14 0-23.7 10.4-7.9 8.5-11.1 21.6-9.7 38.9l-9.1.8z"
						/>
						<path
							className="prefix__st2"
							d="M171.6 274.8v-2c0-1.7-1.6-3.1-3.5-3.3-2.8-.3-5.4-1-7.6-1.8-1.8-.7-2.6-2.7-2-4.2s2.4-1.9 4.2-1.2c3 1.2 6.6 2 10.3 2 6.6 0 11.1-3.3 11.1-7.9 0-4.4-3.6-7.2-10.5-9.6-9.5-3.2-15.4-6.9-15.4-13.9 0-5.7 4.1-10.3 10.6-12.2 1.9-.5 3.4-2.1 3.4-3.8v-2c0-1.7 1.3-3.1 2.9-3.1 1.6 0 2.9 1.4 2.9 3.1v1.7c0 1.7 1.6 3.1 3.5 3.3 2.1.3 3.9.7 5.4 1.2 1.8.6 2.7 2.5 2 4-.6 1.5-2.5 1.9-4.3 1.3-2.1-.7-4.9-1.4-8.6-1.4-7.2 0-9.9 3.7-9.9 6.9 0 4.2 3.5 6.3 11.6 9.2 9.6 3.4 14.5 7.6 14.5 14.7 0 5.6-3.9 10.8-11.3 13-1.9.5-3.4 2.1-3.4 3.8v2.2c0 1.7-1.3 3.1-2.9 3.1-1.7 0-3-1.4-3-3.1z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("returned_requests"),
			count: `${data && data.Return_Requests_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 263.8 340.5">
						<style>
							{
								".prefix__st0{fill:#a4c284}.prefix__st1{fill:#b4975a}"
							}
						</style>
						<path
							className="prefix__st0"
							d="M70.4 340.5c-2.6 0-6.4-.6-9.8-3.3-5-4-7.4-11.2-7.1-21.4V109.5c-.4-2.1-1.6-11.4 4-18.4 4.1-5.1 10.7-7.7 19.6-7.7H176c.9 0 1.9.4 2.5 1.1l84.3 87.7c1 1 1.2 2.5.7 3.8s-1.8 2.1-3.2 2.1h-56.9c-1.6.1-12.1.7-19.2-5.3-3.8-3.2-5.9-7.7-6.3-13.3V93.8l-3.3-3.4H77.2c-6.7 0-11.5 1.7-14.2 5.1-4.2 5.2-2.6 12.9-2.6 13 .1.2.1.5.1.7V316c-.2 7.8 1.3 13.3 4.5 15.9 3.1 2.6 7.1 1.7 7.2 1.7.3-.1.6-.1.8-.1h126v7H73.3c-.6-.2-1.6 0-2.9 0zm114.4-239.6v58.2c.2 3.4 1.5 6.2 3.8 8.2 5.2 4.5 14.1 3.7 14.2 3.7h49.3l-67.3-70.1z"
						/>
						<path
							className="prefix__st0"
							d="M200 298.3H74.8c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5h125.1c1.9 0 3.5 1.6 3.5 3.5 0 2-1.5 3.5-3.4 3.5zM200 317.2H74.8c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5h125.1c1.9 0 3.5 1.6 3.5 3.5s-1.5 3.5-3.4 3.5zM128.8 269h-53c-1.9 0-3.5-1.6-3.5-3.5s1.6-3.5 3.5-3.5h53c1.9 0 3.5 1.6 3.5 3.5s-1.6 3.5-3.5 3.5z"
						/>
						<path
							className="prefix__st1"
							d="M39 326.1c-1.9 0-3.5-1.6-3.5-3.5V62.9c0-1.9 1.6-3.5 3.5-3.5h134.2c1.9 0 3.5 1.6 3.5 3.5v10.9c0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5v-7.4H42.5v256.3c0 1.8-1.6 3.4-3.5 3.4z"
						/>
						<path
							className="prefix__st1"
							d="M18.6 302.1c-1.9 0-3.5-1.6-3.5-3.5V32.2c0-1.9 1.6-3.5 3.5-3.5h146.6c1.9 0 3.5 1.6 3.5 3.5v20.5c0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5v-17H22.1v263c0 1.9-1.6 3.4-3.5 3.4z"
						/>
						<path
							className="prefix__st1"
							d="M3.5 257.7c-1.9 0-3.5-1.6-3.5-3.5V3.5C0 1.6 1.6 0 3.5 0h153c1.9 0 3.5 1.6 3.5 3.5v19.3c0 1.9-1.6 3.5-3.5 3.5s-3.5-1.6-3.5-3.5V7H7v247.3c0 1.9-1.6 3.4-3.5 3.4z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("under_process_requests"),
			count: `${data && data.Under_Process_Requests_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 278.8 340.7">
						<style>{".prefix__st2{fill:#a4c284}"}</style>
						<path
							d="M22.8 340.6c-3.5 0-8.5-.8-12.9-4.4-6.6-5.3-9.8-14.9-9.4-28.4V34.6C0 31.8-1.6 19.5 5.9 10.2 11.4 3.4 20.1 0 31.9 0h130.9c1.3 0 2.5.5 3.3 1.4l111.4 116.3c1.3 1.3 1.6 3.3.9 5-.7 1.7-2.4 2.8-4.2 2.8h-75.4c-2.1.2-16 1-25.4-7-5.1-4.3-7.9-10.2-8.4-17.6v-87l-4.4-4.6H31.8c-8.9 0-15.2 2.3-18.8 6.8-5.5 6.8-3.5 17.1-3.4 17.2.1.3.1.7.1 1V308c-.3 10.4 1.8 17.6 5.9 21s9.4 2.2 9.6 2.2c.3-.1.7-.1 1.1-.1h166.8v9.2H26.7c-.8.2-2.1.3-3.9.3zM174.3 23.5v77.1c.3 4.6 2 8.2 5.1 10.9 6.9 5.9 18.7 4.9 18.8 4.8h65.2l-89.1-92.8z"
							fill="#b4975a"
						/>
						<path
							className="prefix__st2"
							d="M73.4 340.5H30.3c-2.5 0-4.6-2.1-4.6-4.6V99.3c0-2.5 2.1-4.6 4.6-4.6h43.1c2.5 0 4.6 2.1 4.6 4.6v236.6c0 2.6-2.1 4.6-4.6 4.6zm-38.5-9.2h33.9V103.9H34.9v227.4z"
						/>
						<path
							className="prefix__st2"
							d="M45.6 130.9H30.3c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6h15.3c2.5 0 4.6 2.1 4.6 4.6 0 2.6-2.1 4.6-4.6 4.6zM45.6 161.5H30.3c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6h15.3c2.5 0 4.6 2.1 4.6 4.6 0 2.5-2.1 4.6-4.6 4.6zM45.6 192H30.3c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6h15.3c2.5 0 4.6 2.1 4.6 4.6 0 2.6-2.1 4.6-4.6 4.6zM45.6 222.6H30.3c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6h15.3c2.5 0 4.6 2.1 4.6 4.6s-2.1 4.6-4.6 4.6zM45.6 253.1H30.3c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6h15.3c2.5 0 4.6 2.1 4.6 4.6s-2.1 4.6-4.6 4.6zM45.6 283.7H30.3c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6h15.3c2.5 0 4.6 2.1 4.6 4.6s-2.1 4.6-4.6 4.6zM45.6 314.3H30.3c-2.5 0-4.6-2.1-4.6-4.6s2.1-4.6 4.6-4.6h15.3c2.5 0 4.6 2.1 4.6 4.6s-2.1 4.6-4.6 4.6zM269.6 340.7H104.3c-2.5 0-4.6-2.1-4.6-4.6V88.6c0-2 1.3-3.8 3.3-4.4 1.9-.6 4 .2 5.2 1.8l165.3 247.5c.9 1.4 1 3.2.2 4.7s-2.4 2.5-4.1 2.5zm-160.7-9.2H261L108.9 103.7v227.8z"
						/>
						<path
							className="prefix__st2"
							d="M177.3 290.8h-38.8c-2.5 0-4.6-2.1-4.6-4.6v-66c0-2.1 1.4-3.9 3.4-4.4s4.1.3 5.2 2.1l38.9 66c.8 1.4.8 3.2 0 4.6-.9 1.4-2.4 2.3-4.1 2.3zm-34.2-9.2h26.2l-26.2-44.5v44.5z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("completed_requests"),
			count: `${data && data.Compeleted_Requests_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 218.1 340.5">
						<style>
							{
								".prefix__st0{fill:#a4c284}.prefix__st1{fill:#b4975a}"
							}
						</style>
						<path
							className="prefix__st0"
							d="M77.2 143.3H77c-1.1-.1-2.1-.6-2.8-1.4l-25.5-28.6 6.1-5.4 22.8 25.5 50.1-45.6 5.5 6-16.4 15.1L80 142.2c-.8.7-1.7 1.1-2.8 1.1z"
						/>
						<path
							className="prefix__st0"
							d="M85.6 189.1c-40.8 0-74-33.2-74-74s33.2-74 74-74 74 33.2 74 74-33.2 74-74 74zm0-140c-36.3 0-65.9 29.6-65.9 65.9s29.5 65.9 65.9 65.9c36.3 0 65.9-29.6 65.9-65.9s-29.6-65.9-65.9-65.9z"
						/>
						<path
							className="prefix__st1"
							d="M117.9 221.4H15.6c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1h102.2c2.3 0 4.1 1.8 4.1 4.1s-1.8 4.1-4 4.1zM91.1 250.1H15.7c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1h75.4c2.3 0 4.1 1.8 4.1 4.1s-1.9 4.1-4.1 4.1z"
						/>
						<path
							className="prefix__st1"
							d="M65.9 340.5c-.8 0-1.7-.3-2.4-.8l-18.2-13.3c-6.4-4.7-13-7.6-19.5-8.6-1.4-.2-2.9-.6-4.3-1C8.6 312.6 0 300.3 0 286.1V155.7c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1V286c0 10.5 6.5 19.9 15.8 22.9 1 .3 2.1.6 3.1.7 7.8 1.2 15.6 4.6 23.1 10l15.7 11.5 24.5-19c.7-.6 1.6-.9 2.5-.9h102.4c2.8-1.6 13.1-8.6 14.7-24.5v-74.8l-1.7-176.1V4.1c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1v31.7l1.6 176.3v75.2c-2.3 23.3-19.4 31.6-20.1 31.9-.5.3-1.1.4-1.7.4h-102l-25.8 20c-.9.6-1.7.9-2.6.9z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("rejected_requests"),
			count: `${data && data.Rejected_Requests_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 218.1 340.7">
						<style>
							{
								".prefix__st0{fill:#a4c284}.prefix__st1{fill:#b4975a}"
							}
						</style>
						<path
							className="prefix__st0"
							d="M85.7 189.3c-40.8 0-74-33.2-74-74s33.2-74 74-74 74.1 33.2 74.1 74-33.3 74-74.1 74zm0-140c-36.3 0-65.9 29.5-65.9 65.9s29.5 65.9 65.9 65.9c36.3 0 65.9-29.5 65.9-65.9S122 49.3 85.7 49.3z"
						/>
						<path
							className="prefix__st1"
							d="M117.9 221.6H15.7c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1h102.2c2.3 0 4.1 1.8 4.1 4.1s-1.8 4.1-4.1 4.1zM91.1 250.3H15.7c-2.3 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1h75.4c2.3 0 4.1 1.8 4.1 4.1s-1.8 4.1-4.1 4.1z"
						/>
						<path
							className="prefix__st1"
							d="M65.9 340.7c-.8 0-1.7-.3-2.4-.8l-18.2-13.3c-6.4-4.7-13-7.6-19.5-8.6-1.4-.2-2.9-.6-4.3-1C8.9 312.9 0 300.3 0 286.3V155.9c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1v130.3c0 10.5 6.5 19.9 15.8 22.9 1 .3 2.1.6 3 .7 7.8 1.2 15.6 4.6 23.1 10l15.7 11.5 24.5-19c.7-.6 1.6-.9 2.5-.9h89.5c14.1 0 26-10.3 27.5-23.9l.1-.6v-74.8l-1.6-176.3V4.1c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1v31.7l1.6 176.3-.1 76.3c-2 17.8-17.4 31.2-35.7 31.2H94.2l-25.8 20c-.7.8-1.6 1.1-2.5 1.1z"
						/>
						<path
							transform="rotate(-41.236 84.051 113.928)"
							className="prefix__st0"
							d="M47.6 109.8h72.9v8.2H47.6z"
						/>
						<path
							transform="rotate(-48.775 87.245 116.447)"
							className="prefix__st0"
							d="M83.1 80h8.2v72.9h-8.2z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("insurance_fees_fines"),
			count: `${data && data.Payments_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 197.6 340.5">
						<style>
							{
								".prefix__st0{fill:#b4975a}.prefix__st1{fill:#a4c284}"
							}
						</style>
						<path
							className="prefix__st0"
							d="M59.4 340.5c-5.8 0-11.7-1.9-16.5-5.8l-2.1-1.7c-5.7-4.6-11.5-7.4-17.3-8.4-1.3-.2-2.7-.6-4-1.1C7.8 319.3 0 307.1 0 293V163.2c0-2.2 1.8-4.1 4.1-4.1 2.2 0 4.1 1.8 4.1 4.1V293c0 10.5 5.8 19.9 14.1 22.9.9.3 1.8.5 2.6.7 7.2 1.2 14.3 4.6 21.1 10.1l2.1 1.7c6.8 5.5 16.5 5.3 23.1-.3l4.1-3.5c4.7-4.1 10.8-6.3 17.1-6.3h76.7c6 0 11.5-2.8 14.6-7.4 3-4.4 4.9-9.7 5.6-15.7.1-.7.1-1.5.1-2.2v-73.5c0-2.3 1.8-4.1 4.1-4.1s4.1 1.8 4.1 4.1V293c0 1.1 0 2.2-.2 3.2-.9 7.3-3.2 13.8-7 19.3-4.7 6.9-12.7 11-21.4 11H92.3c-4.3 0-8.5 1.5-11.8 4.4l-4.1 3.5c-4.8 4-10.9 6.1-17 6.1z"
						/>
						<path
							className="prefix__st0"
							d="M117.3 228.6H15.6c-2.2 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1h101.8c2.2 0 4.1 1.8 4.1 4.1-.1 2.3-1.9 4.1-4.2 4.1z"
						/>
						<path
							className="prefix__st1"
							d="M104.6 0H.1v193.1h104.5V0zM18.5 185.6c0-4.2-4.1-7.6-9.2-7.6h-.4V15h.4c5.1 0 9.2-3.4 9.2-7.6v-.3h67.7v.3c0 4.2 4.1 7.6 9.2 7.6h.4v163h-.4c-5.1 0-9.2 3.4-9.2 7.6v.3H18.4c.1-.1.1-.2.1-.3z"
						/>
						<path
							className="prefix__st0"
							d="M90.7 257.2H15.6c-2.2 0-4.1-1.8-4.1-4.1s1.8-4.1 4.1-4.1h75.1c2.2 0 4.1 1.8 4.1 4.1-.1 2.3-1.9 4.1-4.1 4.1zM193.4 176.4c-2.3 0-4.1-1.8-4.1-4.1v-58.6c0-1.1-.5-26.7-30.1-45.6-1.9-1.2-2.5-3.7-1.2-5.6 1.2-1.9 3.7-2.4 5.6-1.2 33.5 21.4 33.9 51.2 33.9 52.4v58.6c0 2.3-1.8 4.1-4.1 4.1z"
						/>
						<path
							className="prefix__st0"
							d="M152.7 151.4c-.9 0-1.8-.3-2.6-.9-.9-.7-21.1-17.7-15.6-43.1l-16.6-16.5c-4.5-4.5-5.7-11.2-3.1-17 2.6-5.7 8.3-9.1 14.5-8.7h.5c.9.1 1.7.4 2.3 1l48 42c1.7 1.5 1.9 4.1.4 5.7-1.5 1.7-4.1 1.9-5.7.4l-46.9-41c-3.7.3-5.2 3-5.6 3.9-1 2.2-1.1 5.4 1.4 7.9l18.2 18.1c1 1 1.4 2.5 1.1 4-5.9 21.6 12.2 36.9 12.4 37 1.7 1.4 2 4 .5 5.7-.9 1-2 1.5-3.2 1.5zM143.1 171.4c-.4 0-.8-.1-1.3-.2-32.3-10.5-28.5-48.8-28.4-49.2.2-2.2 2.3-3.8 4.5-3.6 2.2.2 3.8 2.2 3.6 4.5-.1 1.3-3.1 32.2 22.8 40.6 2.1.7 3.3 3 2.6 5.1-.4 1.7-2 2.8-3.8 2.8z"
						/>
						<path
							className="prefix__st1"
							d="M47.7 135.8v-2.6c0-2.2-2.1-4-4.6-4.3-3.6-.4-7.1-1.3-10-2.4-2.4-.9-3.4-3.5-2.6-5.5.8-1.9 3.2-2.5 5.5-1.6 3.9 1.5 8.6 2.6 13.5 2.6 8.7 0 14.6-4.3 14.6-10.3 0-5.8-4.8-9.4-13.8-12.6-12.5-4.2-20.2-9.1-20.2-18.2 0-7.5 5.3-13.5 13.9-16 2.4-.7 4.5-2.8 4.5-5v-2.6c0-2.2 1.7-4 3.8-4 2.1 0 3.8 1.8 3.8 4v2.2c0 2.2 2.1 4 4.6 4.4 2.7.3 5.1.9 7.1 1.6 2.4.8 3.5 3.3 2.6 5.2-.8 1.9-3.3 2.5-5.7 1.7-2.8-1-6.5-1.8-11.2-1.8-9.4 0-13 4.9-13 9.1 0 5.5 4.5 8.2 15.2 12 12.6 4.4 19 9.9 19 19.3 0 7.3-5.1 14.2-14.8 17-2.4.7-4.5 2.8-4.5 5v2.9c0 2.2-1.7 4-3.9 4-2.1 0-3.8-1.8-3.8-4.1z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("technical_support_requests"),
			count: `${data && data.Services_Support_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 410.5 325.4">
						<style>
							{
								".prefix__st0{fill:#a4c284}.prefix__st1{fill:#b4975a}"
							}
						</style>
						<path
							className="prefix__st0"
							d="M242.2 156.7c-31.1 0-64.5-23.1-66.3-24.4-49.4-32.8-49.4-99.9-49.4-102.8 0-1.2.5-2.3 1.3-3.2l24.9-24.9c1.8-1.8 4.6-1.8 6.4-.1l36.5 35.3c.9.9 1.4 2 1.4 3.2s-.5 2.4-1.4 3.3l-23.8 23.3-6.3-6.5L185.9 40 156 11l-20.5 20.4c.4 10.7 4.3 66.2 45.4 93.4.6.4 53.7 37.1 83.2 16.5l11.5-11.5-25.5-23.9-17.4 17.7-6.5-6.4 20.5-20.9c1.7-1.8 4.5-1.8 6.3-.1l32.2 30.1c.9.8 1.4 2 1.4 3.2s-.5 2.4-1.3 3.3l-15.1 15.1c-.2.2-.4.3-.6.5-8.2 6-17.7 8.3-27.4 8.3z"
						/>
						<path
							className="prefix__st1"
							d="M128.5 325.4H4.6c-1.3 0-2.6-.6-3.4-1.6-.9-1-1.3-2.3-1.1-3.6.1-.6 8.7-58.9 70.7-58.9h1c1.6-.1 43.5-1.2 61.1 58.3.4 1.4.1 2.9-.7 4-.9 1.1-2.3 1.8-3.7 1.8zm-118.3-9.1h112.1C106 269.8 73.4 270.4 72 270.4h-.9c-44.5 0-57.5 32.9-60.9 45.9z"
						/>
						<path
							className="prefix__st0"
							d="M268.5 325.4H144.6c-1.3 0-2.6-.6-3.4-1.6-.9-1-1.3-2.3-1.1-3.6.1-.6 8.7-58.9 70.7-58.9h1c1.5-.1 43.5-1.2 61.1 58.3.4 1.4.1 2.9-.7 4-.9 1.1-2.3 1.8-3.7 1.8zm-118.4-9.1h112.1c-16.5-46.9-50-46-50.3-45.9-45.3-.3-58.3 32.8-61.8 45.9z"
						/>
						<path
							className="prefix__st1"
							d="M406 325.4H282.1c-1.3 0-2.6-.6-3.4-1.6-.9-1-1.3-2.3-1.1-3.6.1-.6 8.7-58.9 70.7-58.9h1c1.5-.1 43.5-1.2 61.1 58.3.4 1.4.1 2.9-.7 4-.9 1.1-2.3 1.8-3.7 1.8zm-118.3-9.1h112.1c-16.5-46.9-50-46-50.3-45.9h-.9c-44.7-.1-57.6 32.9-60.9 45.9zM66.6 258.8c-21.7 0-39.3-17.6-39.3-39.3s17.6-39.3 39.3-39.3c21.7 0 39.3 17.6 39.3 39.3-.1 21.7-17.7 39.3-39.3 39.3zm0-69.5c-16.7 0-30.2 13.6-30.2 30.2 0 16.7 13.6 30.2 30.2 30.2 16.7 0 30.2-13.6 30.2-30.2 0-16.7-13.6-30.2-30.2-30.2z"
						/>
						<path
							className="prefix__st0"
							d="M202 258.8c-21.7 0-39.3-17.6-39.3-39.3s17.6-39.3 39.3-39.3 39.3 17.6 39.3 39.3c-.1 21.7-17.7 39.3-39.3 39.3zm0-69.5c-16.7 0-30.2 13.6-30.2 30.2 0 16.7 13.6 30.2 30.2 30.2 16.7 0 30.2-13.6 30.2-30.2 0-16.7-13.6-30.2-30.2-30.2z"
						/>
						<path
							className="prefix__st1"
							d="M346.7 258.8c-21.7 0-39.3-17.6-39.3-39.3s17.6-39.3 39.3-39.3 39.3 17.6 39.3 39.3c0 21.7-17.6 39.3-39.3 39.3zm0-69.5c-16.7 0-30.2 13.6-30.2 30.2 0 16.7 13.6 30.2 30.2 30.2 16.7 0 30.2-13.6 30.2-30.2 0-16.7-13.5-30.2-30.2-30.2z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("complaints_suggestions"),
			count: `${data && data.Suggestions_Count}`,
			icon: (
				<>
					<svg x={0} y={0} viewBox="0 0 410 167.7">
						<style>
							{
								".prefix__st0{fill:#b4975a}.prefix__st1{fill:#a4c284}"
							}
						</style>
						<path
							className="prefix__st0"
							d="M106.7 135.2H3.7c-1.1 0-2.1-.5-2.8-1.3-.7-.8-1-1.9-.9-3 .1-.5 7.4-49.5 59.6-49 1.3 0 36.1-1 50.7 48.4.3 1.1.1 2.4-.6 3.3-.7 1.1-1.8 1.6-3 1.6zm-98.3-7.5h93.1C87.8 88.8 60 89.5 59.7 89.5H59c-37 0-47.8 27.4-50.6 38.2z"
						/>
						<path
							className="prefix__st1"
							d="M406.2 135.2h-103c-1.1 0-2.1-.5-2.8-1.3s-1-1.9-.9-3c.1-.5 7.3-49.5 59.6-49 1.3 0 36.1-1 50.7 48.4.3 1.1.1 2.4-.6 3.3-.7 1.1-1.8 1.6-3 1.6zm-98.3-7.5H401c-13.5-38.6-40.6-38.1-41.8-38.2h-.7c-37 0-47.8 27.4-50.6 38.2z"
						/>
						<path
							className="prefix__st0"
							d="M55.2 79.9c-18 0-32.6-14.6-32.6-32.6s14.6-32.6 32.6-32.6 32.6 14.6 32.6 32.6-14.6 32.6-32.6 32.6zm0-57.7c-13.8 0-25.1 11.3-25.1 25.1s11.3 25.1 25.1 25.1 25.1-11.3 25.1-25.1c0-13.9-11.3-25.1-25.1-25.1z"
						/>
						<path
							className="prefix__st1"
							d="M350.9 79.9c-18 0-32.6-14.6-32.6-32.6s14.6-32.6 32.6-32.6 32.6 14.6 32.6 32.6c.1 18-14.6 32.6-32.6 32.6zm0-57.7c-13.8 0-25.1 11.3-25.1 25.1s11.3 25.1 25.1 25.1S376 61.1 376 47.3c0-13.9-11.2-25.1-25.1-25.1zM244 167.7c-6.8 0-13.5-2.7-14.5-3.1-.5-.2-.9-.5-1.3-.9l-22.6-23.5h-53.9c-.4 0-.9-.1-1.3-.2-10.8-4-9.2-20.1-8.9-23V56.3c0-6.3 2.6-9.7 4.8-11.5 4.8-3.9 11.3-3.2 13.1-2.8h118.9c7 0 10.3 7.2 11.1 11.4 0 .2.1.5.1.7v71.8c0 .2 0 .5-.1.7-2.3 12-13.2 14.4-18.7 14h-12.3v16.1c0 .3 0 .5-.1.8-.8 3.9-3 6.8-6.3 8.5-2.3 1.2-5.2 1.7-8 1.7zm-10.9-9.8c3.4 1.3 11.4 3.5 15.5 1.3 1.2-.6 1.9-1.6 2.3-3.1v-19.4c0-2.1 1.7-3.8 3.8-3.8h16.4c1.2.1 9.3.4 11-7.5v-71c-.3-1.4-1.6-5-3.6-5H159.2c-.3 0-.5 0-.8-.1-1.3-.3-5.1-.5-7.3 1.2-1.3 1.1-2 3-2 5.6v61.4c-.6 4.1-.4 12.8 3.5 15h54.7c1 0 2 .4 2.7 1.2l23.1 24.2z"
						/>
						<path
							className="prefix__st1"
							d="M174.2 104.3c-7.4 0-13.4-6-13.4-13.4s6-13.4 13.4-13.4 13.4 6 13.4 13.4-6 13.4-13.4 13.4zm0-19.3c-3.2 0-5.9 2.6-5.9 5.9s2.6 5.9 5.9 5.9 5.9-2.6 5.9-5.9-2.6-5.9-5.9-5.9zM210 104.3c-3.4 0-6.9-1.3-9.5-3.9-5.2-5.2-5.2-13.7 0-18.9 5.2-5.2 13.7-5.2 18.9 0s5.2 13.7 0 18.9c-2.5 2.6-6 3.9-9.4 3.9zm0-19.3c-1.6 0-3 .6-4.1 1.7s-1.7 2.6-1.7 4.1c0 1.6.6 3 1.7 4.1 2.2 2.2 6.1 2.2 8.3 0 1.1-1.1 1.7-2.6 1.7-4.1 0-1.6-.6-3-1.7-4.1S211.6 85 210 85zM243.6 104.3c-3.4 0-6.9-1.3-9.5-3.9-5.2-5.2-5.2-13.7 0-18.9 5.2-5.2 13.7-5.2 18.9 0 5.2 5.2 5.2 13.7 0 18.9-2.6 2.6-6 3.9-9.4 3.9zm0-19.3c-1.6 0-3 .6-4.1 1.7s-1.7 2.6-1.7 4.1c0 1.6.6 3 1.7 4.1 2.2 2.2 6.1 2.2 8.3 0 1.1-1.1 1.7-2.6 1.7-4.1 0-1.6-.6-3-1.7-4.1s-2.7-1.7-4.2-1.7z"
						/>
						<path
							className="prefix__st0"
							d="M145.2 124.1h-20.9c-.3 0-.5 0-.8-.1-4-.8-11.7-5.1-11.7-15.5V28.9c0-2.1 1.7-3.8 3.8-3.8h27.3V9.2c-.1-1.2-.2-5.1 2.6-7.5 1.5-1.3 4.2-2.5 8.6-1.1.6.2 1.1.5 1.6.9l24.2 24.2h45.7c1.4-.1 6.7-.3 11.1 3.4 3.5 3 5.4 7.4 5.7 13.4l-7.5.3c-.2-3.7-1.2-6.3-3-7.9-2.4-2-5.6-1.7-5.7-1.7h-48c-1 0-2-.4-2.7-1.1L150.9 7.5c-.6-.1-.9 0-.9 0 .2 0 .1.7.2 1v20.4c0 2.1-1.7 3.8-3.8 3.8h-27.3v75.8c0 5.9 4 7.6 5.4 8H145v7.6h.2z"
						/>
					</svg>
				</>
			),
		},
		{
			title: t("inspection_dates"),
			count: `${data && data.Inspection_Visits_Count}`,
			icon: (
				<>
					{/* <svg
            x={0}
            y={0}
            viewBox="0 0 410 167.7"
          >
            <style>{".prefix__st0{fill:#b4975a}.prefix__st1{fill:#a4c284}"}</style>
            <path
              className="prefix__st0"
              d="M106.7 135.2H3.7c-1.1 0-2.1-.5-2.8-1.3-.7-.8-1-1.9-.9-3 .1-.5 7.4-49.5 59.6-49 1.3 0 36.1-1 50.7 48.4.3 1.1.1 2.4-.6 3.3-.7 1.1-1.8 1.6-3 1.6zm-98.3-7.5h93.1C87.8 88.8 60 89.5 59.7 89.5H59c-37 0-47.8 27.4-50.6 38.2z"
            />
            <path
              className="prefix__st1"
              d="M406.2 135.2h-103c-1.1 0-2.1-.5-2.8-1.3s-1-1.9-.9-3c.1-.5 7.3-49.5 59.6-49 1.3 0 36.1-1 50.7 48.4.3 1.1.1 2.4-.6 3.3-.7 1.1-1.8 1.6-3 1.6zm-98.3-7.5H401c-13.5-38.6-40.6-38.1-41.8-38.2h-.7c-37 0-47.8 27.4-50.6 38.2z"
            />
            <path
              className="prefix__st0"
              d="M55.2 79.9c-18 0-32.6-14.6-32.6-32.6s14.6-32.6 32.6-32.6 32.6 14.6 32.6 32.6-14.6 32.6-32.6 32.6zm0-57.7c-13.8 0-25.1 11.3-25.1 25.1s11.3 25.1 25.1 25.1 25.1-11.3 25.1-25.1c0-13.9-11.3-25.1-25.1-25.1z"
            />
            <path
              className="prefix__st1"
              d="M350.9 79.9c-18 0-32.6-14.6-32.6-32.6s14.6-32.6 32.6-32.6 32.6 14.6 32.6 32.6c.1 18-14.6 32.6-32.6 32.6zm0-57.7c-13.8 0-25.1 11.3-25.1 25.1s11.3 25.1 25.1 25.1S376 61.1 376 47.3c0-13.9-11.2-25.1-25.1-25.1zM244 167.7c-6.8 0-13.5-2.7-14.5-3.1-.5-.2-.9-.5-1.3-.9l-22.6-23.5h-53.9c-.4 0-.9-.1-1.3-.2-10.8-4-9.2-20.1-8.9-23V56.3c0-6.3 2.6-9.7 4.8-11.5 4.8-3.9 11.3-3.2 13.1-2.8h118.9c7 0 10.3 7.2 11.1 11.4 0 .2.1.5.1.7v71.8c0 .2 0 .5-.1.7-2.3 12-13.2 14.4-18.7 14h-12.3v16.1c0 .3 0 .5-.1.8-.8 3.9-3 6.8-6.3 8.5-2.3 1.2-5.2 1.7-8 1.7zm-10.9-9.8c3.4 1.3 11.4 3.5 15.5 1.3 1.2-.6 1.9-1.6 2.3-3.1v-19.4c0-2.1 1.7-3.8 3.8-3.8h16.4c1.2.1 9.3.4 11-7.5v-71c-.3-1.4-1.6-5-3.6-5H159.2c-.3 0-.5 0-.8-.1-1.3-.3-5.1-.5-7.3 1.2-1.3 1.1-2 3-2 5.6v61.4c-.6 4.1-.4 12.8 3.5 15h54.7c1 0 2 .4 2.7 1.2l23.1 24.2z"
            />
            <path
              className="prefix__st1"
              d="M174.2 104.3c-7.4 0-13.4-6-13.4-13.4s6-13.4 13.4-13.4 13.4 6 13.4 13.4-6 13.4-13.4 13.4zm0-19.3c-3.2 0-5.9 2.6-5.9 5.9s2.6 5.9 5.9 5.9 5.9-2.6 5.9-5.9-2.6-5.9-5.9-5.9zM210 104.3c-3.4 0-6.9-1.3-9.5-3.9-5.2-5.2-5.2-13.7 0-18.9 5.2-5.2 13.7-5.2 18.9 0s5.2 13.7 0 18.9c-2.5 2.6-6 3.9-9.4 3.9zm0-19.3c-1.6 0-3 .6-4.1 1.7s-1.7 2.6-1.7 4.1c0 1.6.6 3 1.7 4.1 2.2 2.2 6.1 2.2 8.3 0 1.1-1.1 1.7-2.6 1.7-4.1 0-1.6-.6-3-1.7-4.1S211.6 85 210 85zM243.6 104.3c-3.4 0-6.9-1.3-9.5-3.9-5.2-5.2-5.2-13.7 0-18.9 5.2-5.2 13.7-5.2 18.9 0 5.2 5.2 5.2 13.7 0 18.9-2.6 2.6-6 3.9-9.4 3.9zm0-19.3c-1.6 0-3 .6-4.1 1.7s-1.7 2.6-1.7 4.1c0 1.6.6 3 1.7 4.1 2.2 2.2 6.1 2.2 8.3 0 1.1-1.1 1.7-2.6 1.7-4.1 0-1.6-.6-3-1.7-4.1s-2.7-1.7-4.2-1.7z"
            />
            <path
              className="prefix__st0"
              d="M145.2 124.1h-20.9c-.3 0-.5 0-.8-.1-4-.8-11.7-5.1-11.7-15.5V28.9c0-2.1 1.7-3.8 3.8-3.8h27.3V9.2c-.1-1.2-.2-5.1 2.6-7.5 1.5-1.3 4.2-2.5 8.6-1.1.6.2 1.1.5 1.6.9l24.2 24.2h45.7c1.4-.1 6.7-.3 11.1 3.4 3.5 3 5.4 7.4 5.7 13.4l-7.5.3c-.2-3.7-1.2-6.3-3-7.9-2.4-2-5.6-1.7-5.7-1.7h-48c-1 0-2-.4-2.7-1.1L150.9 7.5c-.6-.1-.9 0-.9 0 .2 0 .1.7.2 1v20.4c0 2.1-1.7 3.8-3.8 3.8h-27.3v75.8c0 5.9 4 7.6 5.4 8H145v7.6h.2z"
            />
          </svg> */}
				</>
			),
		},
	];

	const dataIsReady = (data) => {
		setIsReady(data);
	};

	const showGrid = (item) => {
		setActiveGrid(item.title);
	};

	return (
		<>
			<Header />

			<Container fluid="lg">
				<Row className={"pb-3"}>
					<Col xl={12} className="text-center">
						<p className={style.title}>{t("Dashboard")}</p>
					</Col>
				</Row>

				{data ? (
					<Row className="flex-wrap justify-content-center">
						{list.map((item) => {
							return (
								<Col
									key={item.title}
									className={"pb-5"}
									xl={3}
									md={4}
								>
									<div
										className={style.container}
										onClick={() => {
											setIsReady(true)
											showGrid(item)
										}}
									>
										<Row className="w-100">
											<Col md={6}>
												<span
													className={
														style.dashboardIcon
													}
												>
													{item.icon}
												</span>
											</Col>
											<Col md={6} className="text-center">
												<h1>{item.count}</h1>
											</Col>
											<Col
												md={12}
												className="pt-3 text-center align-items-center justify-content-center"
											>
												<h3>{item.title}</h3>
											</Col>
										</Row>
									</div>
								</Col>
							);
						})}
					</Row>
				) : null}

				<Row className="gridCon">
					<Col>
						{(() => {
							if (activeGrid === t("drafts")) {
								return <Drafts dataIsReady={dataIsReady} />;
							} else if (activeGrid === t("new_requests")) {
								return <NewRequests dataIsReady={dataIsReady} />;
							} else if (
								activeGrid === t("due_payment_requests")
							) {
								return <DuePayments dataIsReady={dataIsReady} />;
							} else if (activeGrid === t("returned_requests")) {
								return <ReturnRequests dataIsReady={dataIsReady} />;
							} else if (
								activeGrid === t("under_process_requests")
							) {
								return <UnderProcessRequests dataIsReady={dataIsReady} />;
							} else if (activeGrid === t("completed_requests")) {
								return <CompletedRequests dataIsReady={dataIsReady} />;
							} else if (activeGrid === t("rejected_requests")) {
								return <RejectedRequests dataIsReady={dataIsReady} />;
							} else if (
								activeGrid === t("insurance_fees_fines")
							) {
								return <Payments dataIsReady={dataIsReady} />;
							} else if (
								activeGrid === t("technical_support_requests")
							) {
								return <ServicesSupport dataIsReady={dataIsReady} />;
							} else if (
								activeGrid === t("complaints_suggestions")
							) {
								return <Suggestions dataIsReady={dataIsReady} />;
							} else if (activeGrid === t("inspection_dates")) {
								return <InspectionDates dataIsReady={dataIsReady} />;
							}
						})()}
					</Col>
				</Row>

				<ScrollButton />
			</Container>

			<Footer />

			<ViolationsSuspensions />

			{isReady && <LoadingOverlay />}
		</>
	);
};

export default Dashboard;
