import React, { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import style from "./DyanmicField.module.scss";
import { Col, Form } from "react-bootstrap";
import FieldsLookup from "./FieldsLookup";
import debounce from "lodash.debounce";
import DynamicDropdown from "./DynamicDropdown";
import DynamicDropdownWithValidation from "./DynamicDropdownWithValidation";
import { Button } from "devextreme-react/button";
import PopUp from "../PopUp/PopUp";
import { FormText } from "react-bootstrap";
import {
  GetDraftInputsDataValidation,
  GetRequestInputsDataValidation,
} from "api/dropdownsAPI";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Grid from "components/UIComponents/Grid";
import { Column } from "devextreme-react/data-grid";
import styleNew from "./NewDyanmicField.module.scss";
import * as icons from "./icons";
const FiledType = Object.freeze({
  textArea: 4,
  dropdownWithValidation: 11,
  dropdown: 2,
  checkbox: 7,
  number: 5,
  date: 6,
  email: 9,
  phone: 8,
  fle: 3,
  textWithValidation: 10,
  text: 1,
  general: 12,
});

const DynamicField = ({
  item,
  defaultValue,
  onSave = async () => { },
  inputsData,
  autoSave = false,
}) => {
  if (item.Department_Service_Detail_Type_Id === FiledType.textArea) {
    return (
      <TextareaComponent
        item={item}
        defaultValue={defaultValue}
        onSave={onSave}
      />
    );
  } else if (item.Department_Service_Detail_Type_Id === FiledType.checkbox) {
    return (
      <DynamicCheckBox
        item={item}
        defaultValue={defaultValue}
        onSave={onSave}
      />
    );
  } else if (item.Department_Service_Detail_Type_Id === FiledType.dropdown) {
    return (
      <DynamicDropdown
        item={item}
        defaultValue={defaultValue}
        onSave={onSave}
      />
    );
  } else if (item.Department_Service_Detail_Type_Id === FiledType.dropdownWithValidation) {
    return (
      <DynamicDropdownWithValidation
        item={item}
        defaultValue={defaultValue}
        onSave={onSave}
        inputsData={inputsData}
      />
    );
  } else if (item.Department_Service_Detail_Type_Id === FiledType.number) {
    return (
      <NumberComponent
        item={item}
        defaultValue={defaultValue}
        onSave={onSave}
      />
    );
  } else if (item.Department_Service_Detail_Type_Id === FiledType.email) {
    return (
      <EmailComponent item={item} defaultValue={defaultValue} onSave={onSave} />
    );
  } else if (item.Department_Service_Detail_Type_Id === FiledType.date) {
    return (
      <DateComponent item={item} defaultValue={defaultValue} onSave={onSave} />
    );
  } else if (item.Department_Service_Detail_Type_Id === FiledType.phone) {
    return (
      <PhoneComponent item={item} defaultValue={defaultValue} onSave={onSave} />
    );
  } else if (item.Department_Service_Detail_Type_Id === FiledType.textWithValidation) {
    return (
      <TextWithValidationComponent
        item={item}
        defaultValue={defaultValue}
        onSave={onSave}
        inputsData={inputsData}
        autoSave={autoSave}
      />
    );
  } else if (item.Department_Service_Detail_Type_Id === FiledType.text) {
    return (
      <TextComponent item={item} defaultValue={defaultValue} onSave={onSave} />
    );
  }
  else if (item.Department_Service_Detail_Type_Id === FiledType.general) {
    // return (
    //   <GeneralComponent
    //     item={item}
    //     defaultValue={defaultValue}
    //     onSave={onSave}
    //   />
    // );
    return <></>
  }
  else {
    return (
      <TextComponent item={item} defaultValue={defaultValue} onSave={onSave} />
    );
  }
};

export default DynamicField;

const DynamicCheckBox = ({ item, defaultValue, onSave, autoSave = false }) => {
  const { t, i18n } = useTranslation("common");
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  const [checked, setChecked] = useState(
    defaultValue() ? (defaultValue() === "true" ? true : false) : ""
  );
  const handleChangeWithoutDebounce = () => {
    setChecked(!checked);
  };

  const handleChangeWithDebounce = async (e) => {
    setChecked(e.target.checked);
    let event = { ...e };
    let obj = { target: { value: event.target.checked ? true : false } };
    await onSave(obj, item);
  };

  return (
    <Col
      className={style.checkBoxContainer}
      key={item.Department_Service_Detail_Id}
      md={6}
    >
      <Form.Check
        type="checkbox"
        className={style.checkBox}
        name={item.Service_Detail_Id}
        checked={checked}
        onChange={
          autoSave ? handleChangeWithoutDebounce : handleChangeWithDebounce
        }
        required={item.Department_Service_Detail_Required ? true : false}
      />
      <Form.Label
        onClick={() =>
          handleExpandLabel(
            i18n.language === "ar"
              ? item?.Department_Service_Detail_Title_AR
              : item?.Department_Service_Detail_Title_EN
          )
        }
        className={
          isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
        }
      >
        {i18n.language === "ar"
          ? item?.Department_Service_Detail_Title_AR
          : item?.Department_Service_Detail_Title_EN}
        {item.Department_Service_Detail_Required ? (
          <span className={style.required}>({t("required")})</span>
        ) : (
          <></>
        )}
      </Form.Label>
    </Col>
  );
};

const TextComponent = ({ item, defaultValue, onSave }) => {
  const { t, i18n } = useTranslation("common");
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const [validationError, setValidationError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const onKeyPress = (e) => {
    var ew = e.which;

    if (ew != 8) {
      // if ((ew < 48 || ew > 57) && (ew < 0x0600 || ew > 0x06ff) && ew != 32)  {
      // if (ew >= 65 && ew <= 120 && ew != 32 && ew != 0) {
      //   setValidationError(true);
      //   e.preventDefault();
      //   return false;
      // }
    }

    if (validationError) {
      setValidationError(false);
    }
    return true;
  };

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  const handleChangeWithDebounce = debounce(async (e) => {
    let response = await onSave(e, item);

    // if (response?.RESULT_CODE === 200) {
    //   i18n.language === "en"
    //     ? setErrorMessage(response?.Error_EN)
    //     : setErrorMessage(response?.Error_AR);
    // }

    setValue(e.target.value);
  }, 500);

  return (
    <Col
      // className={style.dynamics}
      key={item.Department_Service_Detail_Id}
      md={12}
    >
      <Form.Label
        onClick={() =>
          handleExpandLabel(
            i18n.language === "ar"
              ? item?.Department_Service_Detail_Title_AR
              : item?.Department_Service_Detail_Title_EN
          )
        }
        className={
          isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
        }
      // className={style.label}
      >
        {i18n.language === "ar"
          ? item?.Department_Service_Detail_Title_AR
          : item?.Department_Service_Detail_Title_EN}
        {item.Department_Service_Detail_Required ? (
          <span className={style.required}>({t("required")})</span>
        ) : (
          <></>
        )}
      </Form.Label>
      <div className={styleNew.inputTextContainer}>
        <Form.Control
          type="text"
          name={item.Service_Detail_Id}
          defaultValue={value}
          onKeyPress={onKeyPress}
          onChange={handleChangeWithDebounce}
          required={item.Department_Service_Detail_Required ? true : false}
        />
        <div className={styleNew.inputTextIcon}>
          <div className={styleNew.numberIconContainer}>
            <button type="button">{icons.textInput}</button>
          </div>
        </div>
      </div>
      {validationError && (
        <Form.Text className={style.onError}>
          {t("Write Arabic only")}
        </Form.Text>
      )}
      {/* {errorMessage?.length > 0 && (
        <Form.Text className={style.onError}>{errorMessage}</Form.Text>
      )} */}
    </Col>
  );
};

///// ---- ////

const GeneralComponent = ({ item, defaultValue, onSave }) => {
  const { t, i18n } = useTranslation("common");
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const [validationError, setValidationError] = useState(false);

  const handleChangeWithDebounce = debounce(async (e) => {
    await onSave(e, item);
    setValue(e.target.value);
  }, 500);

  return (
    <Col
      className={style.dynamics}
      key={item.Department_Service_Detail_Id}
      md={6}
    >
      <Form.Label className={style.label}>
        {i18n.language === "ar"
          ? item?.Department_Service_Detail_Title_AR
          : item?.Department_Service_Detail_Title_EN}
        {item.Department_Service_Detail_Required ? (
          <span className={style.required}>({t("required")})</span>
        ) : (
          <></>
        )}
      </Form.Label>
      <Form.Control
        type="text"
        name={item.Service_Detail_Id}
        defaultValue={value}
        onChange={handleChangeWithDebounce}
        required={item.Department_Service_Detail_Required ? true : false}
      />
      {validationError && (
        <Form.Text className={style.onError}>
          {t("Write Arabic only")}
        </Form.Text>
      )}
    </Col>
  );
};

const TextWithValidationComponent = ({
  item,
  defaultValue,
  onSave,
  inputsData,
  autoSave = false,
}) => {
  const { t, i18n } = useTranslation("common");
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const [validationError, setValidationError] = useState(false);
  const { clientProfiles } = useSelector((state) => state.user);
  const { currentTaskData } = useSelector(
    (state) => state.service.updateRequest
  );

  const { requestId } = useSelector((state) => state.service.serviceRequest);
  const { requestId: paramRequestId } = useParams();
  const [isPopUp, setIsPopUp] = useState(false);
  const [violationText, setViolationText] = useState("");
  const [validationObject, setValidationObject] = useState([false, [], []]);
  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (inputsData && inputsData()) {
      let res = { Data: {} };
      res.Data = inputsData();
      handleMessage(res);
    }
  }, [inputsData]);

  useEffect(() => {
    const subscriber = (data) => {
      // just in case another dropdown in the same page changed so do not fire check func
      if (data.detail.item.Department_Service_Detail_No === 5) {
        handleCheck();
      }
    };
    document.addEventListener("dropDownChanged", subscriber);

    return () => {
      document.removeEventListener("dropDownChanged", subscriber);
    };
  }, []);

  const onKeyPress = (e) => {
    var ew = e.which;

    if (ew != 8) {
      // if ((ew < 48 || ew > 57) && (ew < 0x0600 || ew > 0x06ff) && ew != 32)  {
      if (ew >= 65 && ew <= 120 && ew != 32 && ew != 0) {
        setValidationError(true);
        e.preventDefault();
        return false;
      }
    }

    if (validationError) {
      setValidationError(false);
    }
    return true;
  };

  const handleChangeWithDebounce = debounce(async (e) => {
    await onSave(e, item);
    setValue(e.target.value);
  }, 500);

  let handleOpenViolationPopup = useCallback(() => {
    setIsPopUp(!isPopUp);
  }, [isPopUp]);

  // ahmed
  let handleMessage = (res) => {
    let validationArr = [];
    let violationsArray = [];
    const {
      Service_Detail_Validation_Message_AR,
      Service_Detail_Validation_Message_EN,
      Service_Detail_Value_Contents,
      Service_Detail_Violations_List,
    } = res?.Data;

    if (
      (Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
      i18n.language === "en"
    ) {
      validationArr.push(Service_Detail_Validation_Message_EN);
    } else if (Service_Detail_Validation_Message_AR?.trim() !== "" || null) {
      validationArr.push(Service_Detail_Validation_Message_AR);
    }

    if (Service_Detail_Value_Contents?.trim() !== "" || null) {
      Service_Detail_Value_Contents.toString()
        .split("\r\n")
        .map((ele) => validationArr.push(ele));
    }

    if (
      Service_Detail_Violations_List !== null ||
      Service_Detail_Violations_List !== undefined
    ) {
      violationsArray = Service_Detail_Violations_List;
    }

    if (res?.Data?.Service_Detail_Validation === 1) {
      setValidationObject([true, validationArr, violationsArray]);
    } else if (res?.Data.Service_Detail_Validation === 0) {
      setValidationObject([false, validationArr, violationsArray]);
    }
  };

  let handleCheck = useCallback(() => {
    if (window.location.href.includes("update-request")) {
      GetRequestInputsDataValidation({
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        Request_Id: requestId ? requestId : paramRequestId,
        Task_Id: currentTaskData?.Task_Id,
        Service_Detail_Id: item.Service_Detail_Id,
      })
        .then((res) => handleMessage(res))
        .catch((err) => console.log(err));
    } else {
      GetDraftInputsDataValidation({
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        Request_Draft_Id: requestId ? requestId : paramRequestId,
        Service_Detail_Id: item.Service_Detail_Id,
      })
        .then((res) => handleMessage(res))
        .catch((err) => console.log(err));
    }
  }, []);

  const onInputUntouched = async (e) => {
    await onSave(e, item);
    handleCheck();
  };

  const handleChangeWithoutDebounce = (e) => {
    setValue(e.target.value);
  };

  return (
    <Col
      // className={style.dynamics}
      key={item.Department_Service_Detail_Id}
      md={12}
    >
      <Form.Label
        onClick={() =>
          handleExpandLabel(
            i18n.language === "ar"
              ? item?.Department_Service_Detail_Title_AR
              : item?.Department_Service_Detail_Title_EN
          )
        }
        className={
          isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
        }
      >
        {i18n.language === "ar"
          ? item?.Department_Service_Detail_Title_AR
          : item?.Department_Service_Detail_Title_EN}
        {item.Department_Service_Detail_Required ? (
          <span className={style.required}>({t("required")})</span>
        ) : (
          <></>
        )}
      </Form.Label>
      <div className={styleNew.inputTextContainer}>
        <Form.Control
          type="text"
          // className={style.dynamicTextValidation}
          name={item.Service_Detail_Id}
          defaultValue={value}
          // onKeyPress={onKeyPress}
          onChange={
            autoSave ? handleChangeWithoutDebounce : handleChangeWithDebounce
          }
          required={item.Department_Service_Detail_Required ? true : false}
          {...(autoSave && { onBlur: onInputUntouched })}
        />

        <div className={styleNew.inputTextIcon}>
          <div className={styleNew.numberIconContainer}>
            <button type="button">{icons.textInput}</button>
          </div>
        </div>

        {!autoSave && (
          <Button
            style={{ backgroundColor: "#58743A" }}
            icon="check"
            type="success"
            onClick={handleCheck}
            className="mx-2"
          />
        )}
      </div>
      {validationObject[1]?.map((ele, key) =>
        key === 0 ? (
          <FormText
            className={[
              validationObject[0] === true
                ? style.validationSuccess
                : style.validationError,
              style.validationStateFont,
            ]}
          >
            <div className="font-weight-bold">
              {ele}
              {validationObject[2]?.length > 0 && (
                <span
                  className={style.showViolations}
                  onClick={handleOpenViolationPopup}
                >
                  {i18n.language === "en"
                    ? " - Show Violations"
                    : " - عرض المخالفات"}
                </span>
              )}
            </div>
          </FormText>
        ) : (
          <FormText className={style.validationSuccess}>
            <div> {ele} </div>
          </FormText>
        )
      )}

      <PopUp
        show={isPopUp}
        handleClose={handleOpenViolationPopup}
        title={t("violations")}
        smallModal={false}
      >
        <Grid sourceData={validationObject[2]} editEnabled={false}>
          <Column
            caption={t("violation_Id")}
            alignment="center"
            dataField="Violation_Id"
            dataType="string"
          // width={120}
          />
        <Column
            caption={t("violation_no")}
            alignment="center"
            dataField="Violation_No"
            dataType="string"
        // width={120}
        />
          <Column
            caption={t("violation_date")}
            alignment="center"
            dataField="Violation_Date"
            dataType="string"
          // width={120}
          />
          <Column
            caption={t("violation_type")}
            alignment="center"
            dataField="Violation_Type_Name_AR"
            dataType="string"
          // width={120}
          />
          <Column
            caption={t("violation_value")}
            alignment="center"
            dataField="Violation_Value"
            dataType="string"
          // width={120}
          />
          <Column
            caption={t("violation_on")}
            alignment="center"
            dataField="Violation_Holder_Name_AR"
            dataType="string"
          // width={120}
          />
          <Column
            caption={t("land_piece_no")}
            alignment="center"
            dataField="Plot_No"
            dataType="string"
          // width={120}
          />
          <Column
            caption={t("project_no")}
            alignment="center"
            dataField="Project_No"
            dataType="string"
          // width={120}
          />
          <Column
            caption={t("violation_description")}
            alignment="center"
            dataField="Violation_Description"
            dataType="string"
          // width={120}
          />
        </Grid>
      </PopUp>

      {/* {validationError && (
        <Form.Text className={style.onError}>
          {t("Write Arabic only")}
        </Form.Text>
      )} */}
    </Col>
  );
};

/////

const PhoneComponent = ({ item, defaultValue, onSave }) => {
  const { i18n, t } = useTranslation("common");
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const [validationError, setValidationError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  const onKeyPress = (e) => {
    //   setValidationError(false);
    // }
    return true;
  }; // if (validationError) {

  const handleChangeWithDebounce = debounce(async (e) => {
    let response = await onSave(e, item);

    // if (response?.RESULT_CODE === 200) {
    //   i18n.language === "en"
    //     ? setErrorMessage(response?.Error_EN)
    //     : setErrorMessage(response?.Error_AR);
    // }

    setValue(e.target.value);
  }, 500);

  return (
    <Col
      className={style.dynamics}
      key={item.Department_Service_Detail_Id}
      md={12}
    >
      <Form.Label
        onClick={() =>
          handleExpandLabel(
            setIsTextExpanded,
            i18n.language === "ar"
              ? item?.Department_Service_Detail_Title_AR
              : item?.Department_Service_Detail_Title_EN
          )
        }
        className={
          isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
        }
      >
        {i18n.language === "ar"
          ? item?.Department_Service_Detail_Title_AR
          : item?.Department_Service_Detail_Title_EN}
        {item.Department_Service_Detail_Required ? (
          <span className={style.required}>({t("required")})</span>
        ) : (
          <></>
        )}
      </Form.Label>
      <div className={styleNew.inputTextContainer}>
        <Form.Control
          type="phone"
          name={item.Service_Detail_Id}
          defaultValue={value}
          onKeyPress={onKeyPress}
          onChange={handleChangeWithDebounce}
          required={item.Department_Service_Detail_Required ? true : false}
        />

        <div className={styleNew.inputTextIcon}>
          <div className={styleNew.numberIconContainer}>
            <button type="button">{icons.phoneInput}</button>
          </div>
        </div>
        {/* {errorMessage?.length > 0 && (
        <Form.Text className={style.onError}>{errorMessage}</Form.Text>
      )} */}
        {validationError ? <></> : <></>}
      </div>
    </Col>
  );
};

const DateComponent = ({ item, defaultValue, onSave }) => {
  const { i18n, t } = useTranslation("common");
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const [validationError, setValidationError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  const onKeyPress = (e) => {
    //   setValidationError(false);
    // }
    return true;
  }; // if (validationError) {

  const handleChangeWithDebounce = debounce(async (e) => {
    let response = await onSave(e, item);

    // if (response?.RESULT_CODE === 200) {
    //   i18n.language === "en"
    //     ? setErrorMessage(response?.Error_EN)
    //     : setErrorMessage(response?.Error_AR);
    // }

    setValue(e.target.value);
  }, 500);

  return (
    <Col
      className={style.dynamics}
      key={item.Department_Service_Detail_Id}
      md={12}
    >
      <Form.Label
        onClick={() =>
          handleExpandLabel(
            i18n.language === "ar"
              ? item?.Department_Service_Detail_Title_AR
              : item?.Department_Service_Detail_Title_EN
          )
        }
        className={
          isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
        }
      >
        {i18n.language === "ar"
          ? item?.Department_Service_Detail_Title_AR
          : item?.Department_Service_Detail_Title_EN}
        {item.Department_Service_Detail_Required ? (
          <span className={style.required}>({t("required")})</span>
        ) : (
          <></>
        )}
      </Form.Label>
      <div className={styleNew.inputTextContainer}>
        <Form.Control
          type="date"
          name={item.Service_Detail_Id}
          defaultValue={value}
          onKeyPress={onKeyPress}
          onChange={handleChangeWithDebounce}
          required={item.Department_Service_Detail_Required ? true : false}
        />
        {/* {errorMessage?.length > 0 && (
        <Form.Text className={style.onError}>{errorMessage}</Form.Text>
      )} */}

        {/* <div className={styleNew.inputTextIcon}>
          <div className={styleNew.numberIconContainer}>
            <button type="button">{icons.textInput}</button>
          </div>
        </div> */}
        {validationError ? <></> : <></>}
      </div>
    </Col>
  );
};

const NumberComponent = ({ item, defaultValue, onSave }) => {
  const { i18n, t } = useTranslation("common");
  const [value, setValue] = useState(defaultValue() ? defaultValue() : 0);
  const [validationError, setValidationError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");
  const [isTextExpanded, setIsTextExpanded] = useState(false);
  // const [numberValue, setNumberValue] = useState(value ? value : 0);

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  const handleNumberUpClick = () => {
    // if (response?.RESULT_CODE === 200) {
    //   i18n.language === "en"
    //     ? setErrorMessage(response?.Error_EN)
    //     : setErrorMessage(response?.Error_AR);
    // }

    setValue((prev) => {
      let val = ++prev;
      let obj = { target: {} };

      obj.target.value = val;
      debounce(async () => {
        await onSave(obj, item);
      }, 500)();
      return val;
    });
  };

  const handleNumberDownClick = () => {
    // if (response?.RESULT_CODE === 200) {
    //   i18n.language === "en"
    //     ? setErrorMessage(response?.Error_EN)
    //     : setErrorMessage(response?.Error_AR);
    // }

    setValue((prev) => {
      let val = --prev;
      let obj = { target: {} };

      obj.target.value = val;
      debounce(async () => {
        await onSave(obj, item);
      }, 500)();
      return val;
    });
  };

  const onKeyPress = (e) => {
    //   setValidationError(false);
    // }
    return true;
  }; // if (validationError) {

  const handleChangeWithDebounce = (e) => {
    debounce(async () => {
      await onSave(e, item);
    }, 500)();

    // if (response?.RESULT_CODE === 200) {
    //   i18n.language === "en"
    //     ? setErrorMessage(response?.Error_EN)
    //     : setErrorMessage(response?.Error_AR);
    // }

    setValue(e.target.value);
  };

  return (
    <Col className={style.dynamics} key={item.Department_Service_Detail_Id}>
      <Form.Label
        onClick={() =>
          handleExpandLabel(
            setIsTextExpanded,
            i18n.language === "ar"
              ? item?.Department_Service_Detail_Title_AR
              : item?.Department_Service_Detail_Title_EN
          )
        }
        className={
          isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
        }
      >
        {i18n.language === "ar"
          ? item?.Department_Service_Detail_Title_AR
          : item?.Department_Service_Detail_Title_EN}
        {item.Department_Service_Detail_Required ? (
          <span className={style.required}>({t("required")})</span>
        ) : (
          <></>
        )}
      </Form.Label>
      <div className={styleNew.inputTextContainer}>
        <Form.Control
          type="Number"
          name={item.Service_Detail_Id}
          defaultValue={value}
          value={value}
          onKeyPress={onKeyPress}
          onChange={handleChangeWithDebounce}
          required={item.Department_Service_Detail_Required ? true : false}
        />
        <div className={styleNew.inputTextIcon}>
          <div className={styleNew.numberIconContainer}>
            <button type="button" onClick={handleNumberUpClick}>
              {icons.numberUp}
            </button>
            <button type="button" onClick={handleNumberDownClick}>
              {icons.numberDown}
            </button>
          </div>
        </div>
      </div>

      {validationError ? <></> : <></>}
    </Col>
  );
};

const EmailComponent = ({ item, defaultValue, onSave }) => {
  const { i18n, t } = useTranslation("common");
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const [validationError, setValidationError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  const onKeyPress = (e) => {
    //   setValidationError(false);
    // }
    return true;
  }; // if (validationError) {

  const handleChangeWithDebounce = debounce(async (e) => {
    let response = await onSave(e, item);

    // if (response?.RESULT_CODE === 200) {
    //   i18n.language === "en"
    //     ? setErrorMessage(response?.Error_EN)
    //     : setErrorMessage(response?.Error_AR);
    // }

    setValue(e.target.value);
  }, 500);

  return (
    <Col
      // className={style.dynamics}
      key={item.Department_Service_Detail_Id}
      md={12}
    >
      <Form.Label
        onClick={() =>
          handleExpandLabel(
            i18n.language === "ar"
              ? item?.Department_Service_Detail_Title_AR
              : item?.Department_Service_Detail_Title_EN
          )
        }
        className={
          isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
        }
      >
        {i18n.language === "ar"
          ? item?.Department_Service_Detail_Title_AR
          : item?.Department_Service_Detail_Title_EN}
        {item.Department_Service_Detail_Required ? (
          <span className={style.required}>({t("required")})</span>
        ) : (
          <></>
        )}
      </Form.Label>
      <div className={styleNew.inputTextContainer}>
        <Form.Control
          type="email"
          name={item.Service_Detail_Id}
          defaultValue={value}
          onKeyPress={onKeyPress}
          onChange={handleChangeWithDebounce}
          required={item.Department_Service_Detail_Required ? true : false}
        />
        {/* {errorMessage?.length > 0 && (
        <Form.Text className={style.onError}>{errorMessage}</Form.Text>
      )} */}
        <div className={styleNew.inputTextIcon}>
          <div className={styleNew.numberIconContainer}>
            <button type="button">{icons.emailInput}</button>
          </div>
        </div>
        {validationError ? <></> : <></>}
      </div>
    </Col>
  );
};

const TextareaComponent = ({ item, defaultValue, onSave }) => {
  const { t, i18n } = useTranslation("common");
  const [value, setValue] = useState(defaultValue ? defaultValue : "");
  const [validationError, setValidationError] = useState(false);
  // const [errorMessage, setErrorMessage] = useState("");

  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  const onKeyPress = (e) => {
    var ew = e.which;
    if (ew != 8) {
      // if ((ew < 48 || ew > 57) && (ew < 0x0600 || ew > 0x06ff) && ew != 32) {
      if (ew >= 65 && ew <= 120 && ew != 32 && ew != 0) {
        setValidationError(true);
        e.preventDefault();
        return false;
      }
    }
    if (validationError) {
      setValidationError(false);
    }
    return true;
  };

  const handleChangeWithDebounce = debounce(async (e) => {
    let response = await onSave(e, item);

    // if (response?.RESULT_CODE === 200) {
    //   i18n.language === "en"
    //     ? setErrorMessage(response?.Error_EN)
    //     : setErrorMessage(response?.Error_AR);
    // }

    setValue(e.target.value);
  }, 500);

  return (
    <Col key={item.Department_Service_Detail_Id} md={12}>
      <Form.Label
        onClick={() =>
          handleExpandLabel(
            i18n.language === "ar"
              ? item?.Department_Service_Detail_Title_AR
              : item?.Department_Service_Detail_Title_EN
          )
        }
        className={
          isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
        }
      >
        {i18n.language === "ar"
          ? item?.Department_Service_Detail_Title_AR
          : item?.Department_Service_Detail_Title_EN}
        {item.Department_Service_Detail_Required ? (
          <span className={style.required}>({t("required")})</span>
        ) : (
          <></>
        )}
      </Form.Label>
      <div className={styleNew.inputTextContainer}>
        <Form.Control
          as="textarea"
          name={item.Service_Detail_Id}
          defaultValue={value}
          onKeyPress={onKeyPress}
          onChange={handleChangeWithDebounce}
          required={item.Department_Service_Detail_Required ? true : false}
        />
        {validationError && (
          <Form.Text className={style.onError}>
            {t("Write Arabic only")}
          </Form.Text>
        )}
        {/* {errorMessage?.length > 0 && (
        <Form.Text className={style.onError}>{errorMessage}</Form.Text>
      )} */}
      </div>
    </Col>
  );
};
