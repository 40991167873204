import React, { useEffect, useState } from 'react'
import Header from './../../UIComponents/Header/Header'
import Media from './../../UIComponents/Media/Media'
import AllServices from './../../UIComponents/AllServices/AllServices'
import Housing from './../../UIComponents/Housing/Housing'
import DownloadApp from './../../UIComponents/DownloadApp/DownloadApp'
import ContactUs from './../../UIComponents/ContactUs/ContactUs'
import Footer from './../../UIComponents/Footer/Footer'
import ViolationsSuspensions from './../../UIComponents/ViolationsSuspensions/ViolationsSuspensions'
import { useSelector, useDispatch } from "react-redux"
import { messageAdded } from "features/service/errorMessageSlice";

const Home = () => {

    const { user } = useSelector((state) => state.user)
    const dispatch = useDispatch()

    useEffect(() => {
        if (user && user?.Client_Profiles[0]?.Profile_Active_Status_Id !== 1) {
            dispatch(
                messageAdded({
                    errorEnglish: `Account Status: ${user?.Client_Profiles[0]?.Profile_Active_Status_Name_EN}, Some services will not be available untill the account is activated`,
                    errorArabic: `حالة الحساب: ${user?.Client_Profiles[0]?.Profile_Active_Status_Name_AR}, بعض الخدمات غير متاحة حتى يتم تعديل حالة الحساب الى فعال`,
                    warning: true,
                    errorColor: "warning",
                })
            );
            document.getElementById('theme').style.marginTop = '73px';
            document.getElementById('header').style.top = '73px';
        }
    }, []);

    return (
        <>
            <Header />
            <Media />
            <AllServices homePage={true} />
            <Housing />
            {/* <DownloadApp homePage={true} /> */}
            <ContactUs />
            <Footer />

            <ViolationsSuspensions />
        </>
    )
}

export default Home
