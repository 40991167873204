import React, { useState, useEffect, useCallback } from "react";
import style from "./DyanmicField.module.scss";
import styleNew from "./NewDyanmicField.module.scss";
import { Col, Form } from "react-bootstrap";
import debounce from "lodash.debounce";
import {
  getDraftExternalDataList,
  GetDraftInputsDataValidation,
  getRequestExternalDataList,
  GetRequestInputsDataValidation,
} from "api/dropdownsAPI";
import PopUp from "../PopUp/PopUp";
import { useTranslation } from "react-i18next";
import { FormText } from "react-bootstrap";
import { useSelector } from "react-redux";
import SelectBox from "devextreme-react/select-box";
import { makeStyles } from "@material-ui/core/styles";
import { useParams } from "react-router-dom";
import Grid from "components/UIComponents/Grid";
import { Column } from "devextreme-react/data-grid";
import arrowDownDropDown from "./iconsFolder/arrowDownDropDown.svg";

const useStyles = makeStyles((theme) => ({
  inputRoot: {
    '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-child': {
      padding: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
  },
}));

const DynamicDropdownWithValidation = ({
  item,
  defaultValue,
  onSave = async () => {},
  inputsData,
}) => {
  const { clientProfiles } = useSelector((state) => state.user);

  const { requestId: paramRequestId } = useParams();

  const { requestId } = useSelector((state) => state.service.serviceRequest);
  const { currentTaskData } = useSelector(
    (state) => state.service.updateRequest
  );

  const { t, i18n } = useTranslation("common");
  const [value, setValue] = useState(null);
  // const [inputValue, setInputValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isPopUp, setIsPopUp] = useState(false);
  const [violationText, setViolationText] = useState("");
  const [validationObject, setValidationObject] = useState([false, [], []]);
  const classes = useStyles();

  const [isTextExpanded, setIsTextExpanded] = useState(false);

  const handleExpandLabel = () => {
    setIsTextExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (defaultValue && typeof defaultValue() === "string") {
      //     let value = options.find((ele) => ele.Id === parseInt(defaultValue()));
      //     setInputValue(value);

      setValue(parseInt(defaultValue()));
    }
  }, []);

  useEffect(() => {
    if (window.location.href.includes("update-request")) {
      getRequestExternalDataList({
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        Request_Id: requestId ? requestId : paramRequestId,
        Task_Id: currentTaskData?.Task_Id,
        Service_Detail_Id: item.Service_Detail_Id,
        // Service_Lookup_Id: item?.Department_Service_Detail_Lookup_Id,
      })
        .then((response) => {
          response.Status && setOptions(response?.Data);
        })
        .catch((e) => console.error(e));
    } else {
      getDraftExternalDataList({
        Client_Profile_Id: clientProfiles?.Client_Profile_Id,
        Request_Draft_Id: requestId ? requestId : paramRequestId,
        Service_Detail_Id: item.Service_Detail_Id,
        // Service_Lookup_Id: item?.Department_Service_Detail_Lookup_Id,
      })
        .then((response) => {
          response.Status && setOptions(response?.Data);
        })
        .catch((e) => console.error(e));
    }
  }, []);

  useEffect(() => {
    if (inputsData && inputsData()) {
      let res = { Data: {} };
      res.Data = inputsData();
      handleMessage(res);
    }
  }, [inputsData]);

  ////////////////////////

  // ahmed
  let handleMessage = (res) => {
    let validationArr = [];
    let violationsArray = [];
    const {
      Service_Detail_Validation_Message_AR,
      Service_Detail_Validation_Message_EN,
      Service_Detail_Value_Contents,
      Service_Detail_Violations_List,
    } = res?.Data;

    if (
      (Service_Detail_Validation_Message_EN?.trim() !== "" || null) &&
      i18n.language === "en"
    ) {
      validationArr.push(Service_Detail_Validation_Message_EN);
    } else if (Service_Detail_Validation_Message_AR?.trim() !== "" || null) {
      validationArr.push(Service_Detail_Validation_Message_AR);
    }

    if (Service_Detail_Value_Contents?.trim() !== "" || null) {
      Service_Detail_Value_Contents?.toString()
        .split("\r\n")
        .map((ele) => validationArr.push(ele));
    }

    if (
      Service_Detail_Violations_List !== null ||
      Service_Detail_Violations_List !== undefined
    ) {
      violationsArray = Service_Detail_Violations_List;
    }

    if (res?.Data?.Service_Detail_Validation === 1) {
      setValidationObject([true, validationArr, violationsArray]);
    } else if (res?.Data.Service_Detail_Validation === 0) {
      setValidationObject([false, validationArr, violationsArray]);
    }
  };

  const handleChangeWithDebounce = debounce(async (e) => {
    if (e) {
      let obj = { target: {} };
      obj.target.value = e.value;
      await onSave(obj, item);

      if (window.location.href.includes("update-request")) {
        GetRequestInputsDataValidation({
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Id: requestId ? requestId : paramRequestId,
          Task_Id: currentTaskData?.Task_Id,
          Service_Detail_Id: item.Service_Detail_Id,
        })
          .then((res) => handleMessage(res))
          .catch((err) => console.log(err));
      } else {
        GetDraftInputsDataValidation({
          Client_Profile_Id: clientProfiles?.Client_Profile_Id,
          Request_Draft_Id: requestId ? requestId : paramRequestId,
          Service_Detail_Id: item.Service_Detail_Id,
        })
          .then((res) => handleMessage(res))
          .catch((err) => console.log(err));
      }
      // A fastest workaround to update other components when list changed
      document.dispatchEvent(
        new CustomEvent("dropDownChanged", { detail: { item } })
      );
    }
  }, 500);

  // ahmed
  let handleOpenViolationPopup = useCallback(() => {
    setIsPopUp(!isPopUp);
  }, [isPopUp]);

  return (
    <Col
      // className={style.dynamics}
      key={item.Department_Service_Detail_Id}
      md={12}
    >
      {item.Department_Service_Detail_Required ? (
        <Form.Label className={style.label}>
          {item?.Department_Service_Detail_Title_AR}
          <span className={style.required}>({t("required")})</span>
        </Form.Label>
      ) : (
        <Form.Label
          onClick={() =>
            handleExpandLabel(
              setIsTextExpanded,
              item?.Department_Service_Detail_Title_AR
            )
          }
          className={
            isTextExpanded ? styleNew.textLabel : styleNew.textExpandedLabel
          }
        >
          {item?.Department_Service_Detail_Title_AR}
        </Form.Label>
      )}
      <div className={style.textValidationContainer}>
        <Form.Control
          type="text"
          className={style.hiddenInput}
          value={value}
          required={item.Department_Service_Detail_Required ? true : false}
        />

        <SelectBox
          className={styleNew.dynamicDropDownBox}
          dataSource={options}
          placeholder="--اختر--"
          searchEnabled={true}
          name={item.Service_Detail_Id}
          value={value}
          displayExpr={i18n.language === "en" ? "Name_EN" : "Name_AR"}
          valueExpr="Id"
          onValueChanged={(e) => {
            setValue(e.value);
            handleChangeWithDebounce(e);
          }}
          dropDownButtonRender={() => (
            <img
              style={{ width: "12px", height: "12px" }}
              alt="dropdown-icon"
              src={arrowDownDropDown}
            />
          )}
        />

        {/* <Autocomplete
          className={style.dynamicDropDown}
          name={item.Service_Detail_Id}
          options={options && options}
          getOptionLabel={(option) =>
            (i18n.language === "en" ? option.Name_EN : option.Name_AR) || ""
          }
          getOptionSelected={(option, value) => option?.Id === value?.Id}
          value={value}
          onChange={(e, value) => {
            handleChangeWithDebounce(e, value);
          }}
          inputValue={inputValue}
          onInputChange={(e, value) => {
            setInputValue(value);
          }}
          classes={classes}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              name={item.Service_Detail_Id}
              fullWidth
              variant="outlined"
              required={item.Department_Service_Detail_Required ? true : false}
            />
          )} */}
        {/* /> */}
      </div>

      {validationObject[1]?.map((ele, key) =>
        key === 0 ? (
          <FormText
            className={[
              validationObject[0] === true
                ? style.validationSuccess
                : style.validationError,
              style.validationStateFont,
            ]}
          >
            <div className="font-weight-bold">
              {ele}
              {validationObject[2]?.length > 0 && (
                <span
                  className={style.showViolations}
                  onClick={handleOpenViolationPopup}
                >
                  {i18n.language === "en"
                    ? " - Show Violations"
                    : " - عرض المخالفات"}
                </span>
              )}
            </div>
          </FormText>
        ) : (
          <FormText className={style.validationSuccess}>
            <div> {ele} </div>
          </FormText>
        )
      )}
      <PopUp
        show={isPopUp}
        handleClose={handleOpenViolationPopup}
        title={t("violations")}
        smallModal={false}
      >
        <Grid sourceData={validationObject[2]} editEnabled={false}>
          <Column
            caption={t("violation_Id")}
            alignment="center"
            dataField="Violation_Id"
            dataType="string"
            // width={120}
          />
            <Column
                caption={t("violation_no")}
                alignment="center"
                dataField="Violation_No"
                dataType="string"
            // width={120}
            />
          <Column
            caption={t("violation_date")}
            alignment="center"
            dataField="Violation_Date"
            dataType="string"
            // width={120}
          />
          <Column
            caption={t("violation_type")}
            alignment="center"
            dataField="Violation_Type_Name_AR"
            dataType="string"
            // width={120}
          />
          <Column
            caption={t("violation_value")}
            alignment="center"
            dataField="Violation_Value"
            dataType="string"
            // width={120}
          />
          <Column
            caption={t("violation_on")}
            alignment="center"
            dataField="Violation_Holder_Name_AR"
            dataType="string"
            // width={120}
          />
          <Column
            caption={t("land_piece_no")}
            alignment="center"
            dataField="Plot_No"
            dataType="string"
            // width={120}
          />
          <Column
            caption={t("project_no")}
            alignment="center"
            dataField="Project_No"
            dataType="string"
            // width={120}
          />
          <Column
            caption={t("violation_description")}
            alignment="center"
            dataField="Violation_Description"
            dataType="string"
            // width={120}
          />
        </Grid>
      </PopUp>
      {/* <Form.Text className={style.text}>هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوىة</Form.Text> */}
    </Col>
  );
};

export default DynamicDropdownWithValidation;

{
  /* <Form.Control
          as="select"
          name={item.Service_Detail_Id}
          onChange={handleChangeWithDebounce}
          required={item.Department_Service_Detail_Required ? true : false}
        >
          <option value="" hidden disabled selected>
            --أختر--
          </option>

          {options?.length > 0 &&
            options.map((data) => (
              <option
                key={data?.Id}
                value={data.Id.toString()}
                selected={data.Id.toString() === value}
              >
                {data?.Name_AR}
              </option>
            ))}
        </Form.Control> */
}

{
  /* {Object.entries(validationObject[1])?.map((ele, key) =>
        key === 0 ? (
          <FormText
            className={[
              validationObject[0] === true
                ? style.validationSuccess
                : style.validationError,
              style.validationStateFont,
            ]}
          >
            <span className="font-weight-bold">
              {ele[1]
                .split(" ")
                .map((ele, key) =>
                  key === 3 ? (
                    <span
                      className={style.cursorPointer}
                      onClick={handleOpenViolationPopup}
                    >{`${ele} `}</span>
                  ) : (
                    <span>{`${ele} `}</span>
                  )
                )}
            </span>
          </FormText>
        ) : (
          <FormText className={style.validationSuccess}>
            <span className="font-weight-bold"> {ele[0]} </span>: {ele[1]}
          </FormText>
        )
      )} */
}

{
  /* <SelectBox
          placeholder="--اختر--"
          name={item.Service_Detail_Id}
          defaultValue={parseInt(value)}
          className="w-100"
          displayExpr="Name_AR"
          valueExpr="Id"
          dataSource={options}
          searchEnabled={true}
          onValueChanged={handleChangeWithDebounce}
        /> */
}
